// Import our CSS
import '../css/app.pcss';

// App main
// const main = async () => {
//     // Async load the vue module
//     // const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
//     // Create our vue instance
//     // const vm = new Vue({
//     //     el: "#app",
//     //     components: {
//     //         'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
//     //     },
//     //     data: {
//     //     },
//     //     methods: {
//     //     },
//     //     mounted() {
//     //     },
//     // });
//     //
//     // return vm;
// };

// Execute async function
// main().then(
//     // (vm) => {}
// );

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Lazy load embedded YouTube videos
( function() {

    var youtube = document.querySelectorAll( ".youtube" );

    for (var i = 0; i < youtube.length; i++) {

        var source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/sddefault.jpg";

        var image = new Image();
        image.src = source;
        image.addEventListener( "load", function() {
            youtube[ i ].appendChild( image );
        }( i ) );

        youtube[i].addEventListener( "click", function() {

            var iframe = document.createElement( "iframe" );

            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?rel=0&showinfo=0&autoplay=1" );

            this.innerHTML = "";
            this.appendChild( iframe );
        } );
    };

} )();

// Flickity
// var Flickity = require('flickity-fade');

// Main menu
var menuItems = document.querySelectorAll('li.more');
Array.prototype.forEach.call(menuItems, function(el, i){
    var btn = '<button><span><span class="visually-hidden">show submenu</span></span></button>';
    var topLevelLink = el.querySelector('a.has-submenu');
    topLevelLink.innerHTML = topLevelLink.innerHTML + ' ' + btn;

    el.querySelector('a button').addEventListener("click",  function(event){
        if (this.parentNode.parentNode.classList.contains("open")) {
            this.parentNode.parentNode.classList.remove("open");
            this.parentNode.setAttribute('aria-expanded', "false");
            this.querySelector('.visually-hidden').innerText = 'show submenu';
        } else {
            this.parentNode.parentNode.classList.add("open");
            this.parentNode.setAttribute('aria-expanded', "true");
            this.querySelector('.visually-hidden').innerText = 'hide submenu';
        }
        event.preventDefault();
    });
});
(function() {
    // get button and menu nodes
    var button = document.querySelector('#nav-button');
    var menu = document.querySelector('#main-nav');
    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    menu.hidden = true;
    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded',String(!expanded));
        menu.hidden = expanded;
    });
})();

import { LuminousGallery } from 'luminous-lightbox';
import { Luminous } from 'luminous-lightbox';

// Luminous lightbox
(function() {
    new LuminousGallery(document.querySelectorAll(".luminous-gallery"), {},{
        caption: function(elem) {
            if (elem.querySelector('figcaption')) {
                return elem.querySelector('figcaption').innerText;
            }
            return '';
        }
    });
})();
// Luminous lightbox
(function() {
    // get lightbox images
    let lightboxImages = document.querySelectorAll(".luminous");
    let options = {
        caption: function(elem) {
            if (elem.querySelector('figcaption')) {
                return elem.querySelector('figcaption').innerText
            }
            return '';
        }
    };
    Array.prototype.forEach.call(lightboxImages, function(el, i) {
        new Luminous(el, options);
    });
})();
